html, body, #root {
  height: 100%;
}

body {
  background: linear-gradient(to top, #A2DEAA 0%, #43C2C1 100%);
  font-family: 'Montserrat', sans-serif;
}

ion-app, ion-router-outlet {
  contain: none !important;
}

.MuiFab-root:hover {
  /*background: #64CBBA !important;*/
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Karla', sans-serif !important;
}

.MuiBottomNavigationAction-root {
  padding-bottom: 16px !important;
  min-width: 66px !important;
}

.Mui-highlight-disabled {
  color: rgba(255, 255, 255, 0.56) !important;
}

.Mui-highlight-disabled.Mui-disabled {
  color: rgba(61,137,128, 0.56) !important;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined {
  padding: 8px 32px 8px 8px;
  width: 75px;
}

#no-desktop {
  position: fixed;
  background: #fefefe;
  color: rgba(0, 0, 0, 0.56);
  font-size: 21px;
  text-align: center;
  padding-top: 200px;
  width: 100%;
  height: 100%;
  z-index: 10000;
  max-width: none;
}

#no-desktop {display: none;}

a {
  color: #44AB9A;
  text-decoration: none;
  font-weight: 500;
}

textarea,
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}
